import * as React from "react"
import { graphql, useStaticQuery} from "gatsby"
import { Section, SectionHeader, SectionContent, SectionColumn } from "@layouts/Section"
import * as Spacer from "@atoms/Spacer"
import Seo from "@components/atoms/Seo"
import { RoomList } from "@projects/RoomList"
import ListController from "@projects/ListController"
import Border from "@atoms/Border"

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

//ログイン後のindexページ
//ログインしてない場合は全部indexにリダイレクト
function RoomPage({ data }) {
    const { room, ogpImage } = useStaticQuery(graphql`
          query {
            room :allWpRoom(
                sort: { fields: date, order: DESC }
                #limit: 3
                skip: 0
            ) {
                edges {
                    node {
                        ...Room
                    }
                }
            }
            ogpImage : file(relativePath: { eq: "OGP_room.png" }) {
                publicURL
            }
        }
    `)
    return (
        <>
            <Seo
                subtitle="Room"
                image={ogpImage.publicURL}
            />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="Room"
                    text="オフショット"
                />
                <SectionContent isBorder={false}>
                    {/* <SectionColumn>
                        <ListController label="期間" />
                        <ListController label="カテゴリ" />
                    </SectionColumn> */}
                    {/* <Border /> */}
                    <Router basepath="/member/room">
                        <PrivateRoute path="/" component={RoomList} data={room.edges} isPickup={false}/>
                    </Router>
                </SectionContent>
                {/* <SectionMoreLink
                    title="More"
                    text="もっとみる"
                    to="/news/"
                /> */}
            </Section>
        </>
    )
}

// export const pagerQuery = graphql`
//     fragment Background on ImageSharp {
//         gatsbyImageData(
//             width: 1440
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//             quality: 100
//         )
//     }
//     fragment Thumbnail on ImageSharp {
//         gatsbyImageData(
//             width: 1440
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//             quality: 100
//         )
//     }
//     fragment Card on ImageSharp {
//         gatsbyImageData(
//             width: 230
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//             quality: 100
//         )
//     }
// `
export default RoomPage
